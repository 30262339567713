// Foundation variable settings, copied as of 6.2
@import "settings";
// Core foundation
@import "./foundation-sites/scss/foundation";
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@import "@waynestate/wsuheader/src/header";

.skip ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.skip a, form label {
    position: absolute;
    width: 1px;
    height: 1px;
    text-align: left;
    overflow: hidden;
    color: #000;
    background-color: #fff;
    padding: 2px;
    left: -1000px;
}

.skip a:active,
.skip a:focus,
.skip a:hover {
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 50;
    width: auto;
    height: auto;
}

html {
    background-color: #fef8f1;
}

.center-site {
    @include breakpoint(large up) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.content {
    &, h1, h2, h3, h4, h5, h6 {
        font-family: 'Noto Sans';
        font-weight: 400;
    }

    a {
        text-decoration: underline;
    }

    h1 {
        @include breakpoint(medium down) {
            text-align: center;
        }

        color: #6b7175;
        font-size: 7em;
    }

    h2 {
        @include breakpoint(medium down) {
            font-size: 1.5em;
        }

        font-size: 2em;
    }
}

.missing-graphic {
    @include breakpoint(medium down) {
        background-position: center top;
    }

    min-height: 350px;
    background: transparent url("/_resources/images/404.png") no-repeat center center;
    background-size: 70% auto;
}
